<template lang="pug">
  v-dialog(v-model='display', lazy, full-width, :width='width', :disabled='disabled')
    v-text-field(slot='activator', :label='label', :value='formattedDatetime', :disabled='disabled', :loading='loading', :error-messages='errorMessages', :error-count='errorCount', :error='error', :hide-details='hideDetails', :append-icon='appendIcon', :prepend-icon='prependIcon', readonly)
    v-card
      v-card-text(style='padding: 0;')
        v-tabs(fixed-tabs, v-model='activeTab')
          v-tab(key='calendar')
            slot(name='dateIcon')
              v-icon event
          v-tab(key='timer', :disabled='!dateSelected')
            slot(name='timeIcon')
              v-icon access_time
          v-tab-item(key='calendar')
            v-date-picker(full-width, v-model='datePart', scrollable, :locale='locale', actions)  
          v-tab-item(key='timer')
            v-time-picker.v-time-picker-custom(ref='timer', full-width, v-model='timePart', scrollable, :format='timePickerFormat', actions, :use-seconds='useSeconds')
      v-card-actions
        v-spacer
        slot(name='actions', :parent='this')
          v-btn(color='gray', flat, @click.native='clearHandler') {{ clearText }} 
          v-btn(color='bt-primary', @click='okHandler').white--text {{ okText }} 


</template>

<script>
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
const DEFAULT_TIME_FORMAT = 'HH:mm:ss'
const DEFAULT_TIME = '00:00:00'
export default {
  name: 'v-datetime-picker',
  model: {
    prop: 'datetime',
    event: 'input'
  },
  props: {
    datetime: {
      type: [Date, String],
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 340
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss'
    },
    timePickerFormat: {
      type: String,
      default: '24hr'
    },
    locale: {
      type: String,
      default: 'pt-br'
    },
    clearText: {
      type: String,
      default: 'LIMPAR'
    },
    okText: {
      type: String,
      default: 'OK'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
    errorCount: {
      type: [Number, String],
      default: 1
    },
    error: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    appendIcon: {
      type: String
    },
    prependIcon: {
      type: String
    },
    useSeconds: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      display: false,
      dateSelected: false,
      timeSelected: false,
      activeTab: 0,
      selectedDatetime: null
    }
  },
  created() {
    if (this.datetime instanceof Date) {
      this.selectedDatetime = this.datetime
    } else if (typeof this.datetime === 'string' || this.datetime instanceof String) {
      // see https://stackoverflow.com/a/9436948
      this.selectedDatetime = this.$moment(this.datetime, this.format)
    }
  },
  computed: {
    datePart: {
      get() {
        const val = this.selectedDatetime ? this.$moment(this.selectedDatetime).format(DEFAULT_DATE_FORMAT) : ''
        return val
      },
      set(val) {
        this.dateSelected = true
        this.activeTab = 1
        const date = this.$moment(val, DEFAULT_DATE_FORMAT)
        const hour = this.selectedDatetime ? this.$moment(this.selectedDatetime).hour() : 0
        const minute = this.selectedDatetime ? this.$moment(this.selectedDatetime).minute() : 0
        const input = this.$moment()
          .year(date.year())
          .month(date.month())
          .date(date.date())
          .hour(hour)
          .minute(minute)
          .second(0)
          .millisecond(0)
        this.selectedDatetime = input.toDate()
      }
    },
    timePart: {
      get() {
        const val = this.selectedDatetime ? this.$moment(this.selectedDatetime).format(DEFAULT_TIME_FORMAT) : DEFAULT_TIME
        return val
      },
      set(val) {
        this.timeSelected = true
        const time = this.$moment(val, DEFAULT_TIME_FORMAT)
        const input = this.$moment(this.selectedDatetime)
          .hour(time.hour())
          .minute(time.minute())
          .second(time.second())
          .millisecond(time.millisecond())
        this.selectedDatetime = input.toDate()
      }
    },
    formattedDatetime() {
      return this.datetime ? this.$moment(this.datetime).format(this.format) : ''
    }
  },
  methods: {
    okHandler() {
      this.display = false
      this.activeTab = 0
      this.$refs.timer.selectingHour = true
      this.$emit('input', this.selectedDatetime)
    },
    clearHandler() {
      this.display = false
      this.activeTab = 0
      this.$refs.timer.selectingHour = true
      this.$emit('input', null)
    }
  }
}
</script>

<style lang="scss" scoped>
  
</style>