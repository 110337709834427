<template lang="pug">
#app
  v-container(grid-list-md, fluid)
    v-layout(row, wrap)
      v-flex(lg12)
        form(@submit.prevent="salvar")
          v-card.elevation-1
            v-card-text
              v-layout(row, wrap)
                v-flex.md12.xs12
                  v-text-field(label="Título", color="gray", required, v-validate="'required'", :error-messages="errors.collect('titulo')", data-vv-name="titulo", data-vv-as="Título", type="text", v-model='notificacao.titulo')
                  v-text-field(label="Conteúdo", color="gray", required, v-validate="'required'", :error-messages="errors.collect('descricao')", data-vv-name="descricao", data-vv-as="Conteúdo", type="text", v-model='notificacao.descricao')
                  v-switch.mt-4(:false-value="0", :true-value="1", :color="notificacao.tipo ? 'primary': ''", :label="notificacao.tipo ? 'Agendar envio' : 'Enviar agora'", v-model="notificacao.tipo")

                  v-layout(v-if="notificacao.tipo === 1")
                    v-flex.md3.xs6
                      v-datetime-picker(color="primary", label="Selecionar data e hora", format="DD/MM/YYYY HH:mm:ss", required, v-model="notificacao.data_hora")
                      //- v-text {{ notificacao.data_hora | moment("YYYY-MM-DD HH:mm:ss") }}

              v-layout(row, wrap)
                v-flex.xs12
                  v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar
</template>

<script>
import _ from 'lodash';
import DatetimePicker from "@/components/DatetimePicker";

export default {

  components: {
    "v-datetime-picker": DatetimePicker,
  },
  $_veeValidate: {
    validator: 'new'
  },
  data: () => ({
    loading: false,
    notificacao: {},
    filter: { ativo: 0 },
    date: new Date().toISOString().substr(0, 10),
    time: null,
  }),
  mounted () {
    const vm = this;
    if (vm.$route.params.id) {
      vm.getNotificacao(vm.$route.params);
    }
  },
  methods: {
    async save (notificacao) {
      const vm = this;
      try {
        notificacao.data_hora = this.$moment(notificacao.data_hora).format("YYYY-MM-DD HH:mm:ss");
        const response = await vm.$axios.post('/notificacao', notificacao);
        notificacao.id = response.data.id;
        vm.$router.push({ name: 'NotificacaoLista'});
        vm.loading = false;
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Notificação salva com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar a notificação. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async update (notificacao) {
      const vm = this;
      try {
        notificacao.data_hora = this.$moment(notificacao.data_hora).format("YYYY-MM-DD HH:mm:ss");
        const response = await vm.$axios.put(`/notificacao/${notificacao.id}`, notificacao);
        if (response && response.data && response.data.id) {
          vm.loading = false;
          window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Notificação salva com sucesso!' });
        } else {
          throw false;
        }
        
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao atualizar a notificação. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async salvar (scope) {
      const vm = this;
      try {
        vm.loading = true;  
        const result = await vm.$validator.validateAll();
        if (!result) throw 'Preencha todos os campos corretamente!';
        vm[vm.notificacao.id ? 'update' : 'save'](vm.notificacao);
        vm.$validator.reset();
      } catch (error) {
        window.getApp.$emit('APP_ALERT', { color: 'red', text: error });
        vm.loading = false;
      }
    },
    async getNotificacao (params) {
      let vm = this;
      try {
        const response = await vm.$axios.get(`/notificacao/${params.id}`);
        if (response && response.data && response.data.id) {
          let notification = _.cloneDeep(response.data) || {};
          notification.data_hora = this.$moment(notification.data_hora).format("DD/MM/YYYY HH:mm:ss");
          vm.notificacao =  notification;
        } else {
          throw false;
        }
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>

